import React, { useContext, useEffect, useState } from "react";
import BagBlack from "../../../assets/Bag-black.svg";
import DashboardContext from "../../Context/DashboardContext";

const AllOrders = (props) => {
  // const [active, setActive] = useState(0)
  // const [numpending, setpending] = useState(0);
  // const [numdelivered, setdelivered] = useState(0);
  // const context = useContext(DashboardContext);
  // const { getOrders } = context;

  const ordersCount = props.ordersCount;

  // setActive(c);
  return (
    <div className='abandoned-main'>
      <div className='abandoned-div'>
        <img alt='cart-img' src={BagBlack} className='cart-image' />
        {/* <select>
          <option>This Week</option>
          <option>This Month</option>
          <option>This Year</option>
        </select> */}
      </div>
      <div className='abandoned-body'>
        <div className='abandoned-body-item'>
          <p className='abandoned-body-title'>All Orders</p>
          <p className='cart-percentage'>{ordersCount.all}</p>
        </div>
        <div className='abandoned-body-item'>
          <p className='abandoned-body-title'>Pending</p>
          <p className='cart-percentage'>{ordersCount.pending}</p>
        </div>
        <div className='abandoned-body-item'>
          <p className='abandoned-body-title'>In Progress</p>
          <p className='cart-percentage'>{ordersCount.IN_PROGESS}</p>
        </div>
        <div className='abandoned-body-item'>
          <p className='abandoned-body-title'>Delivered</p>
          <p className='cart-percentage'>
            {ordersCount.complete}
            {/* <span>+0.00%</span> */}
          </p>
        </div>
        {/* <div className='abandoned-body-item'>
          <p className='abandoned-body-title'>Rejected</p>
          <p className='cart-percentage'>
            {ordersCount.reject}
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default AllOrders;
