import React, { useEffect, useState, useRef, useContext } from "react";
// import BagTable from "../../../assets/Bag-table.svg";
// import Plus from "../../../assets/plus.svg";
// import { compareAsc, format } from "date-fns";
import PropTypes from "prop-types";
import { DownloadTableExcel } from "react-export-table-to-excel";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Collapse } from "@mui/material";
import handleImageUpload from "../../Inventory/Utils/HandleImageUpload";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// import DeleteIcon from '@mui/icons-material/Delete';
import {
  alpha,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  useTheme,
} from "@mui/material";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import axios from "axios";
import { apiInstace } from "../../server";

import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import SearchIcon from "@mui/icons-material/Search";
import Vector from "../../../assets/Vector.svg";
// import Calender from "../../../assets/Calendar.svg";
// import Send from "../../../assets/Send.svg";
import ModalType from "./Filter";
import { ModalFilterByDate } from "./Filter";
import Select from "@mui/material/Select";
// import { CheckBox, Highlight } from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import DashboardContext from "../../Context/DashboardContext";
import { toast } from "react-toastify";
// import Select from '@mui/material/Select';
import { copyTextToClipboard } from "../../../handlers";

import { Checkbox, FormControlLabel, FormGroup, FormLabel } from "@mui/material";
import ContentPasteRounded from "@mui/icons-material/ContentPasteRounded";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useDebounce } from "use-debounce";
function createData(name, orderDate, orderType, trackingID, orderTotal, action, status) {
  return {
    name,
    orderDate,
    orderType,
    trackingID,
    orderTotal,
    action,
    status,
  };
}

const rows = [
  createData(
    "Cupcake",
    "12 Aug 2022 - 12:25 am",
    "Cash on Delivery",
    "9348fjr73",
    "$2000.00",
    true,
    "completed"
  ),
  createData(
    "Donut",
    "12 Aug 2022 - 12:25 am",
    "Cash on Delivery",
    "9348fjr73",
    "$2000.00",
    true,
    "pending"
  ),
  createData(
    "Eclair",
    "12 Aug 2022 - 12:25 am",
    "Cash on Delivery",
    "9348fjr73",
    "$2000.00",
    true,
    "inprogress"
  ),
  createData(
    "Frozen yoghurt",
    "12 Aug 2022 - 12:25 am",
    "Cash on Delivery",
    "9348fjr73",
    "$2000.00",
    true,
    "completed"
  ),

  createData(
    "Gingerbread",
    "12 Aug 2022 - 12:25 am",
    "Cash on Delivery",
    "9348fjr73",
    "$2000.00",
    true,
    "completed"
  ),
  createData("Honeycomb", 408, 3.2, 87, 6.5, 3, 3),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 3, 3),
  createData("Jelly Bean", 375, 0.0, 94, 0.0, 3, 3),
  createData("KitKat", 518, 26.0, 65, 7.0, 3, 3),
  createData("Lollipop", 392, 0.2, 98, 0.0, 3, 3),
  createData("Marshmallow", 318, 0, 81, 2.0, 3, 3),
  createData("Nougat", 360, 19.0, 9, 37.0, 3, 3),
  createData("Oreo", 437, 18.0, 63, 4.0, 3, 3),
];

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const headCells = [
  {
    id: "Order_details",
    numeric: false,
    disablePadding: true,
    label: "Order details",
  },
  {
    id: "Customer_details",
    numeric: false,
    disablePadding: true,
    label: "Customer details",
  },
  {
    id: "Address",
    numeric: false,
    disablePadding: true,
    label: "Address",
  },
  // {
  //   id: "Order_Details",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Order Date",
  // },
  {
    id: "Qunatity",
    numeric: true,
    disablePadding: false,
    label: "Quantity",
  },
  // {
  //   id: "trackingId",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Tracking ID",
  // },
  {
    id: "orderTotal",
    numeric: true,
    disablePadding: false,
    label: "Order Total",
  },
  //   {
  //     id: "SharePrice",
  //     numeric: true,
  //     disablePadding: false,
  //     label: "Share Price",
  //   },
  {
    id: "order type",
    numeric: true,
    disablePadding: false,
    label: "Order Type",
  },
  {
    id: "payment status",
    numeric: true,
    disablePadding: false,
    label: "Payment Status",
  },

  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Order Status",
  },
  {
    id: "invoice",
    numeric: true,
    disablePadding: false,
    label: "Invoice",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <CheckBox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell> */}
        {/* <TableCell>

        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  //   onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const [currproduct, setcurrproduct] = useState("");
  const [filterType, setFilterType] = useState(false);
  const [filterModalByDate, setFilterModalByDate] = useState(false);
  const handleOpenModalType = () => {
    setFilterModalByDate(!filterModalByDate);
  };

  const handlModalFilterByDate = () => {
    setFilterModalByDate(!filterModalByDate);
  };

  const setReceiverName = props.setReceiverName;

  const handlesearch = (e) => {
    // const search_data=[];
    // console.log(props.oriorderData);
    // if (e.target.value != "") {
    // console.log(e.target.value);
    // console.log(props.oriorderData);
    // const filteredRows = props.orderData.filter((row) => {
    //   // const curruser = props.users.filter((user) => {
    //   //   return user.id === row?.order?.user_id;
    //   // });
    //   // return (
    //   //   curruser[0]?.first_name.toLowerCase()?.includes(e.target.value.toLowerCase()) ||
    //   //   curruser[0]?.last_name.toLowerCase()?.includes(e.target.value.toLowerCase())
    //   // );
    //   return e.target.value != ""
    //     ? row?.order?.receiver_name.toLowerCase()?.includes(e.target.value.toLowerCase())
    //     : true;
    // });
    // props.setOrderData(filteredRows);
    ///now also filter by order type
    // console.log(filteredRows)
    //   if (props.product === "all") {
    //     let filteredbyordertype = filteredRows.filter((row) => {
    //       // console.log(row?.order_type?.toLowerCase());
    //       return row?.order?.order_type?.toLowerCase() == props.order_type?.toLowerCase();
    //     });
    //     // console.log(filteredbyordertype)
    //     props.setOrderData(filteredbyordertype);
    //   } else {
    //     let filteredbyordertype = filteredRows.filter((row) => {
    //       // console.log(row?.order_type?.toLowerCase());
    //       return (
    //         row?.order?.order_type?.toLowerCase() == props.order_type?.toLowerCase() &&
    //         row?.product?.id === props.product
    //       );
    //     });
    //     // console.log(filteredbyordertype)
    //     props.setOrderData(filteredbyordertype);
    //   }
    // } else {
    //   // setRows(filteredRows);
    //   // console.log(props.order_type.toLowerCase());
    //   if (props.product === "all") {
    //     let filteredbyordertype = props.oriorderData.filter((row) => {
    //       // console.log(row?.order_type?.toLowerCase());
    //       return row?.order?.order_type?.toLowerCase() == props.order_type?.toLowerCase();
    //     });
    //     // console.log(filteredbyordertype)
    //     props.setOrderData(filteredbyordertype);
    //   } else {
    //     let filteredbyordertype = props.oriorderData.filter((row) => {
    //       // console.log(row?.order_type?.toLowerCase());
    //       return (
    //         row?.order?.order_type?.toLowerCase() == props.order_type?.toLowerCase() &&
    //         row?.product?.id === props.product
    //       );
    //     });
    //     // console.log(filteredbyordertype)
    //     props.setOrderData(filteredbyordertype);
    //   }
    // }
    // }
    // else props.setForceFilter((prev) => !prev);
  };

  const handleDuration = async (startDate, endDate) => {
    handleOpenModalType();
    // console.log("called handle duration parent")
    console.log({
      start: startDate,
      end: endDate,
    });

    const filteredRows = await props.oriorderData.filter((row) => {
      const targetDate = new Date(row?.created_at);
      // console.log(targetDate)
      return targetDate >= startDate && targetDate <= endDate;
      // .toLowerCase()
      // ?.includes(e.target.value.toLowerCase());;
    });
    // console.log(filteredRows)

    if (props.product === "all") {
      let filteredbyordertype = filteredRows.filter((row) => {
        // console.log(row?.order_type?.toLowerCase());
        return row?.order?.order_type?.toLowerCase() == props.order_type?.toLowerCase();
      });

      props.setOrderData(filteredbyordertype);
    } else {
      let filteredbyordertype = filteredRows.filter((row) => {
        // console.log(row?.order_type?.toLowerCase());
        return (
          row?.order?.order_type?.toLowerCase() == props.order_type?.toLowerCase() &&
          row?.product?.id === props.product
        );
      });

      props.setOrderData(filteredbyordertype);
    }
  };

  useEffect(() => {
    setcurrproduct(props?.product);
  }, [props]);

  // useEffect(() => {
  //   props.order_type.includes("HOME_DELIVERY") && props.setPaymentStatus("PENDING");
  // }, [props.order_type]);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      <div style={{ display: "flex", width: "100%" }}>
        {numSelected > 0 ? (
          <Typography sx={{ flex: "1 1 100%" }} color='inherit' variant='subtitle1' component='div'>
            {numSelected} selected
          </Typography>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              display: "flex",
              width: "100%",
              justifyContent: "flex-start",
              gap: 2,
            }}>
            <Typography
              sx={{ textAlign: "left", width: "fit-content" }}
              variant='h6'
              id='tableTitle'
              component='div'>
              Customer Orders
            </Typography>
            {/* <div className='filter-div'> */}
            {/* <select
                // options={crops?.results}
                // isClearable={true}
                displayValue="name"
                placeholder="Select shipping type"
                showArrow
                // onSelect={handleCropsFilter}
                // onRemove={handleCropsFilter}
                style={{
                  width:"150px",
                  height:"3rem",
                  searchBox: {
                    border: "none",
                    "border-radius": "8px 0px 0px 8px",
                  },
                  searchWrapper: {
                    border: "none",
                    "border-radius": "8px 0px 0px 8px",
                  },
                  inputField: {
                    padding: "5px 0px 5px 15px",
                    width: "500px",
                    fontSize: "16px",
                  },
                }}
              /> */}
            {/* <select
                // options={equipment?.results}
                displayValue="name"
                placeholder="Brand"
                showArrow
                // onSelect={handleFarmEquipmentFilter}
                // onRemove={handleFarmEquipmentFilter}
                style={{
                  width:"150px",
                  height:"3rem",
                  searchBox: {
                    border: "none",
                    "border-left": "1px solid #5e6366",
                    "border-radius": "0px",
                  },
                  searchWrapper: {
                    border: "none",
                  },
                  inputField: {
                    padding: "5px 0px 5px 15px",
                    width: "150px",
                    fontSize: "16px",
                  },
                }}
              />
  */}
            {/* <Box sx={{ minWidth: 120 }}> */}
            {/* <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Product</InputLabel>

              <Select 
               labelId="demo-simple-select-label"
               id="demo-simple-select"
              label="Product"
              value={props.product} 
              onChange={(e)=>{console.log(props.product);props.setProduct(e.target.value)}} 
              style={{
                width:"150px",
                height:"3rem",
                searchBox: {
                  border: "none",
                  "border-left": "1px solid #5e6366",
                  "border-radius": "0px",
                },
                searchWrapper: {
                  border: "none",
                },
                inputField: {
                  padding: "5px 0px 5px 15px",
                  width: "150px",
                  fontSize: "16px",
                },
              }} 
              >
              
                {
                  props.products_data?.map((item)=>(
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))
                }

              </Select>
              </FormControl>
              </Box> */}

            <Box sx={{}}>
              <FormControl fullWidth sx={{ marginRight: "5px" }}>
                <InputLabel id='demo-simple-select-label'>Product</InputLabel>

                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Product'
                  value={currproduct}
                  onChange={(e) => {
                    // console.log(props.product);
                    props.setProduct(e.target.value);
                  }}
                  style={{
                    width: "150px",
                    height: "3rem",
                    searchBox: {
                      border: "none",
                      "border-left": "1px solid #5e6366",
                      "border-radius": "0px",
                    },
                    searchWrapper: {
                      border: "none",
                    },
                    inputField: {
                      padding: "5px 0px 5px 15px",
                      width: "150px",
                      fontSize: "16px",
                    },
                  }}
                  //   defaultValue={'all'}
                >
                  <MenuItem value='all'>All</MenuItem>
                  {props.products_data?.map((item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{}}>
              <RadioGroup
                value={props.order_type}
                onChange={(e) => {
                  props.setOrder_type([e.target.value]);
                  props.setPaymentStatus(
                    e.target.value === "HOME_DELIVERY" ? "PENDING" : undefined
                  );
                }}
                sx={{
                  minWidth: 300,
                  // margin: "0 8px",
                  // display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  // maxHeight: "100%",
                  flexDirection: "row",
                  width: "100%",
                  flexWrap: "nowrap",
                }}>
                <FormControlLabel
                  value='CASH_ON_DELIVERY'
                  control={<Radio />}
                  label='Cash on Delivery'
                />
                <FormControlLabel value='HOME_DELIVERY' control={<Radio />} label='Prepaid' />
              </RadioGroup>
            </Box>
            {props.order_type.includes("HOME_DELIVERY") && (
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  {props.payment_status && (
                    <InputLabel id='demo-simple-select-label'>Payment Status</InputLabel>
                  )}
                  <Select
                    // labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label={"Product"}
                    value={props.payment_status}
                    onChange={(e) => {
                      props.setPaymentStatus(e.target.value);
                    }}
                    // sx={{ mx: 2 }}
                    style={{
                      // width: "150px",
                      height: "3rem",
                      searchBox: {
                        border: "none",
                        "border-left": "1px solid #5e6366",
                        "border-radius": "0px",
                      },
                      searchWrapper: {
                        border: "none",
                      },
                      inputField: {
                        padding: "5px 0px 5px 15px",
                        width: "150px",
                        fontSize: "16px",
                      },
                    }}
                    //   defaultValue='PENDING'
                  >
                    <MenuItem value='PENDING'>PENDING</MenuItem>
                    <MenuItem value='SUCCESS'>SUCCESS</MenuItem>
                    <MenuItem value='FAILED'>FAILED</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
            {/* </div> */}
          </Box>
        )}
      </div>

      <div>
        {numSelected > 0 ? (
          <Tooltip title='Delete'>
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip>
            <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
              <TextField
                style={{
                  width: "240px",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size='small'
                placeholder='Search'
                onChange={(e) => setReceiverName(e.target.value)}
              />
              <div style={{ display: "flex" }}>
                {/* <div className='table-head-buttons' onClick={() => handleOpenModalType()}>
                  <img alt='img' src={Vector} />
                  <p className='filter-text'>Filter</p>
                </div> */}
                {/* <div
                  className="table-head-buttons"
                  onClick={() => handlModalFilterByDate()}
                >
                  <img alt="img" src={Calender} />
                  <p className="filter-text">Filter</p>
                </div>
                <div className="table-head-buttons">
                  <img alt="img" src={Send} />
                  <p className="filter-text">Share</p>
                </div> */}
                <DownloadTableExcel
                  filename={`orders table - ${props.tableName}`}
                  sheet={props.tableName}
                  currentTableRef={props.tableRef.current}>
                  <button className='table-head-buttons'> Export excel </button>
                </DownloadTableExcel>
              </div>
              {/* <IconButton>
                <FilterListIcon />
              </IconButton> */}
            </div>
          </Tooltip>
        )}
      </div>
      <div className='modaltype-div'>
        <ModalType
          filterType={filterType}
          oriorderData={props.oriorderData}
          setOrderData={props.setOrderData}
          orderData={props.orderData}
        />
      </div>
      <div className='modalfilterbydate-div'>
        <ModalFilterByDate filterModalByDate={filterModalByDate} handleDuration={handleDuration} />
      </div>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page: pageIndex, rowsPerPage, onPageChange } = props;
  const page = pageIndex + 1;
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 1);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, Math.max(1, page - 1));
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(1, Math.ceil(count / rowsPerPage)));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 1}
        aria-label='first page'>
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 1} aria-label='previous page'>
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page > Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'>
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page > Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'>
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const ProfitShareRow = ({ id, shares }) => {
  const [open2, setOpen2] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [profitData, setProfitData] = useState([]);
  console.log(id);
  useEffect(() => {
    apiInstace
      .get(`/users/getUsers`, { params: { id } })
      .then(async (user) => {
        const filteredData = user.data;
        // console.log(filteredData);
        // const user_nw = await apiInstace.get(`/users/getUsers`, {
        //   params: { user_id: id, size: 100 },
        // });
        let data = [];

        if (filteredData?.bdo) {
          const bdo = await apiInstace.get(`/users/getUsers`, {
            params: { id: filteredData?.bdo },
          });
          data.push({ ...bdo.data, relation: "bdo", share: shares.bdo });
        }
        if (filteredData?.parent) {
          const parent = await apiInstace.get(`/users/getUsers`, {
            params: { id: filteredData?.parent },
          });
          data.push({ ...parent.data, relation: "parent", share: shares.parent });
        }
        if (filteredData?.grand_parent) {
          const grand_parent = await apiInstace.get(`/users/getUsers`, {
            params: { id: filteredData?.grand_parent },
          });
          data.push({ ...grand_parent.data, relation: "grand_parent", share: shares.grand_parent });
        }
        if (filteredData?.dealer) {
          const dealer = await apiInstace.get(`/users/getUsers`, {
            params: { id: filteredData?.dealer },
          });
          data.push({ ...dealer.data, relation: "dealer", share: shares.dealer });
        }
        // console.log({ filteredData, data });

        setProfitData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //   console.log("filtered", profitData);

  const highlighted = (user) => {
    if (user.relation === "bdo" || user.relation === "dealer") return false;
    let count = 0;
    profitData.forEach((item) => {
      if (user.id === item.id) ++count;
    });
    if (count > 1) return true;
    return false;
  };
  const order = ["BDO", "Dealer", "Grand Parent", "Parent"];
  const customSort = (a, b) => {
    return order.indexOf(a.relation?.toUpperCase()) - order.indexOf(b.relation?.toUpperCase());
  };
  return profitData?.length > 0 ? (
    [...profitData]
      .sort(customSort)
      ?.reverse()
      .map((user) => {
        return (
          <TableRow>
            <TableCell
              align='left'
              sx={{
                color: highlighted(user) ? "red" : "green",
                fontWeight: highlighted(user) && 700,
              }}>
              {user?.first_name} {user?.last_name}
            </TableCell>
            <TableCell align='left'>{user?.relation?.toUpperCase()}</TableCell>
            <TableCell align='left'>{user?.phone}</TableCell>
            <TableCell align='left'>{user?.share}</TableCell>
          </TableRow>
        );
      })
  ) : (
    <p style={{ textAlign: "center" }}>No Data</p>
  );
};

const Row1 = ({ row, setLoading, value, dispTrackID }) => {
  const [Edit, setEdit] = useState("");
  const [trackingID, setTrackingID] = useState("");
  const [open2, setOpen2] = useState(false);
  const [caritemssingleorder, setCartItemsSingleOrder] = useState([]);
  const handletrackingid = (value) => {
    setTrackingID(value);
  };
  const handleSubmit = (id) => {
    // console.log(trackingID[index]);
    const token = `Bearer ${localStorage.getItem("access")}`;
    console.log(id);
    const cart_update = { tracking_id: trackingID };
    apiInstace
      .patch(`/orders/update_cart/${id}`, { cart_update, token })
      .then((res) => {
        console.log(res);
        toast.success("Tracking ID Updated!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        apiInstace
          .get(`/orders/getorders`, { params: { order: row.order.id } })
          .then((res) => {
            // console.log("cart item for orderid" + row.id);
            //     // console.log(res.data.results);
            //     // if (res.data.results[0].product.id === product) {
            //     // setshowrow(true)
            //     // console.log("showing order with id" + row.id)
            //     // }
            //     // let arr = cartitemsall.slice()
            //     // arr.push(res.data.results)
            //     // console.log({ "new allcart" : arr})
            //     // setallCartitems(arr)
            console.log({ orderallcartitems: res.data });
            setCartItemsSingleOrder(res.data.results);
            //     setCartitems(res.data.results);
            // setInvoice1(row?.order?.invoice_url)
          })
          .catch((err) => {
            console.log(err);
          });
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(err);
        setLoading(false);
      });
  };
  const handleEdit = (value) => {
    setEdit(value);
  };

  useEffect(() => {
    apiInstace
      .get(`/orders/getorders`, { params: { order: row.order.id } })
      .then((res) => {
        // console.log("cart item for orderid" + row.id);
        //     // console.log(res.data.results);
        //     // if (res.data.results[0].product.id === product) {
        //     // setshowrow(true)
        //     // console.log("showing order with id" + row.id)
        //     // }
        //     // let arr = cartitemsall.slice()
        //     // arr.push(res.data.results)
        //     // console.log({ "new allcart" : arr})
        //     // setallCartitems(arr)
        console.log({ orderallcartitems: res.data });
        setCartItemsSingleOrder(res.data.results);
        //     setCartitems(res.data.results);
        // setInvoice1(row?.order?.invoice_url)
      })
      .catch((err) => {
        console.log(err);
      });

    // console.log("filtered", filteredData)
  }, []);

  return (
    <TableBody>
      {/* {cartitems.map((row) => ( */}
      {caritemssingleorder &&
        caritemssingleorder.map((cartitem, idx) => {
          //console.log(cartitem)
          return (
            <>
              <TableRow key={cartitem.id}>
                <TableCell component='th' scope='row'>
                  {cartitem.product.name}
                </TableCell>
                <TableCell>{cartitem.quantity}</TableCell>
                {/* <TableCell align="right">{row.amount}</TableCell> */}
                {dispTrackID && (
                  <TableCell align='left'>
                    {
                      //  const [disabled1, setDisabled1] = useState(true);
                      row?.order?.status !== "IN_PROGESS" ? (
                        <>
                          <TextField
                            type='text'
                            value={cartitem.tracking_id}
                            size='small'
                            sx={{ width: "8rem" }}
                            disabled
                          />
                          <Button
                            size='small'
                            variant='contained'
                            style={{ padding: "2px", height: "2rem" }}
                            sx={{ mx: 1 }}
                            disabled>
                            <EditIcon />
                          </Button>
                        </>
                      ) : (
                        <>
                          {
                            // disabled?
                            Edit !== cartitem.id ? (
                              <>
                                <TextField
                                  type='text'
                                  value={cartitem.tracking_id}
                                  size='small'
                                  sx={{ width: "8rem" }}
                                  disabled
                                />

                                <Button
                                  size='small'
                                  variant='contained'
                                  style={{ padding: "2px", height: "2rem" }}
                                  sx={{ mx: 1 }}
                                  onClick={() => {
                                    setTrackingID(cartitem.tracking_id);
                                    handleEdit(cartitem.id);
                                  }}>
                                  <EditIcon />
                                </Button>
                              </>
                            ) : (
                              <>
                                <TextField
                                  type='text'
                                  value={trackingID}
                                  onChange={(e) => handletrackingid(e.target.value)}
                                  size='small'
                                  sx={{ width: "8rem" }}
                                />
                                <div style={{ display: "flex" }}>
                                  <Button
                                    size='small'
                                    variant='contained'
                                    style={{ padding: "2px", height: "2rem" }}
                                    sx={{ mx: 1 }}
                                    onClick={() => {
                                      handleEdit(false);
                                    }}>
                                    <CloseIcon />
                                  </Button>

                                  <Button
                                    size='small'
                                    variant='contained'
                                    style={{ padding: "2px", height: "2rem" }}
                                    sx={{ mx: 1 }}
                                    onClick={() => {
                                      handleSubmit(cartitem.id);
                                      handleEdit("");
                                    }}>
                                    <DoneIcon />
                                  </Button>
                                </div>
                              </>
                            )
                          }
                        </>
                      )
                    }
                  </TableCell>
                )}
                <TableCell align='left'>{cartitem.price}</TableCell>
                <TableCell align='left'>{cartitem?.product?.price}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label='expand row'
                    size='small'
                    onClick={() => setOpen2(open2 === cartitem.id ? -1 : cartitem.id)}>
                    {cartitem.id === open2 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
                {/* <TableCell>
            {row?.order?.status === "DELIVERED" ? (
              <p className="status-complete">Delivered</p>
            ) : row?.order?.status === "IN_PROGESS" ? (
              <p className="status-progress">In-Progress</p>
            ) : (
              <p className="status-pending">{row?.order?.status}</p>
            )}
          </TableCell> */}
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                  <Collapse in={cartitem.id === open2} timeout='auto' unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Table size='small' aria-label='purchases'>
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align='left'>Relation</TableCell>
                            <TableCell align='left'>Number</TableCell>
                            <TableCell align='left'>Share</TableCell>
                            {/* <TableCell align="left">Product Status</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <ProfitShareRow
                            id={cartitem?.order?.user_id}
                            shares={{
                              bdo: cartitem?.product?.bdo_share,
                              parent: cartitem?.product?.parent_share,
                              grand_parent: cartitem?.product?.grand_parent_share,
                              dealer: cartitem?.product?.dealer_share,
                            }}
                          />
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </>
          );
        })}
      {/* ))} */}
    </TableBody>
  );
};

const Row = ({
  row,
  // id,
  orderData,
  // oriorderData,
  // users,
  labelId,
  index,
  setLoading,
  // handleOpen,
  fetchAgain,
  setFetchAgain,
  fetchOrders,
  // cartitemsall,
  // setallCartitems,
  // product,
  value,
}) => {
  // function GetUserDetail({ row, id, index }) {
  //   // setUser({});
  //   const user = users?.filter((item) => {
  //     return item.id == id;
  //   });
  //   // console.log(user.data);
  //   row = { ...row, user: user[0] };
  //   // console.log(row);
  //   orderData[index] = row;
  //   // oriorderData[index] = row; //this line causes an error duplicate row
  //   // setOrderData([...orderData,[index]:row])
  //   // console.log(orderData);
  //   // console.log(user[0]);

  //   return (
  //     <div
  //       style={{ display: "flex", flexDirection: "column" }}
  //       onClick={() =>
  //         copyTextToClipboard(
  //           [[user[0]?.first_name, user[0]?.last_name].join(" "), user[0]?.phone]
  //             .filter((elem) => (elem ? true : false))
  //             .join(", ")
  //         )
  //       }>
  //       {user[0]?.phone ? (
  //         <>
  //           <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
  //             <div></div>
  //             <Button sx={{ position: "relative", top: 0, right: 0 }}>
  //               <ContentPasteRounded fontSize='small' />
  //             </Button>
  //           </div>
  //           {/* <p>{`${user?.first_name} ${user?.last_name}`}</p>
  //     <p>{user?.phone}</p> */}
  //           <p>
  //             {user[0]?.first_name ? <>{user[0]?.first_name}</> : <>---</>}{" "}
  //             {user[0]?.last_name ? <>{user[0]?.last_name}</> : <>---</>}
  //           </p>
  //           <p>{user[0]?.phone}</p>
  //         </>
  //       ) : (
  //         <>---</>
  //       )}
  //     </div>
  //   );
  // }
  // const [status, setStatus] = useState(row.status);

  // useEffect(()=>{
  //   console.log(status);
  // },[status])

  const getPaymentStatus = (status) => {
    switch (status) {
      case "PENDING":
      case "IN_PROGRESS":
        return "PENDING";
      case "REJECTED":
        return "FAILED";
      case "DELIVERED":
        return "SUCCESS";
      default:
        return "PENDING";
    }
  };
  const handleStatus = (value, id) => {
    // setStatus(value);
    const token = `Bearer ${localStorage.getItem("access")}`;
    setLoading(true);
    const order_update =
      row?.order?.order_type == "CASH_ON_DELIVERY"
        ? { status: value, payment_status: getPaymentStatus(value) }
        : { status: value };
    console.log(order_update);
    apiInstace
      .patch(`/orders/update_order/${id}/`, { order_update, token })
      .then((res) => {
        console.log(res);
        toast.success("Status Updated!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
        setFetchAgain(!fetchAgain);
      })
      .catch((err) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(err);
        setLoading(false);
      });
    // navigate("/content")

    //  setStatus({...status,[key]:value})
    // fetchOrders();

  };

  const handlePaymentStatus = (value, id) => {
    // setStatus(value);
    const token = `Bearer ${localStorage.getItem("access")}`;
    setLoading(true);
    const order_update = { payment_status: value };
    console.log(order_update);
    apiInstace
      .patch(`/orders/update_order/${id}/`, { order_update, token })
      .then((res) => {
        console.log(res);
        toast.success("Status Updated!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
        setFetchAgain(!fetchAgain);
      })
      .catch((err) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(err);
        setLoading(false);
      });
    // navigate("/content")

    //  setStatus({...status,[key]:value})
    // fetchOrders();

  };
  const [open1, setOpen1] = useState(false);
  // const [cartitems, setCartitems] = useState([]);
  const requestOptions = {
    params: {
      order: row.order.id,
    },
  };
  useEffect(() => {
    // console.log({ "row": row })
    // apiInstace
    //   .get(`/orders/getorders`, requestOptions)
    //   .then((res) => {
    //     // console.log("cart item for orderid" + row.id);
    //     // console.log(res.data.results);
    //     // if (res.data.results[0].product.id === product) {
    //     // setshowrow(true)
    //     // console.log("showing order with id" + row.id)
    //     // }
    //     // let arr = cartitemsall.slice()
    //     // arr.push(res.data.results)
    //     // console.log({ "new allcart" : arr})
    //     // setallCartitems(arr)
    //     // console.log({"cartitems" : res.data.})
    //     setCartitems(res.data.results);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);

  // useEffect(() => {

  //   console.log("order" + row.id)
  //   console.log({ "cartitem": cartitems })

  // }, [cartitems])

  // const [invoice, setInvoice] = useState("");
  // const [invoice2, setInvoice2] = useState("");
  const [invoice1, setInvoice1] = useState(row?.order?.invoice_url);

  // const [showrow, setshowrow] = useState(true);

  // const [invoiceUpdated, setInvoiceUpdated] = useState(false);

  const handleupload1 = () => {
    const imginput = document.getElementById(`order_invoice${index}`);
    imginput.click();
    const geturl = (e) => {
      const file = e.target.files[0];
      if (!file) return;
      //   setInvoiceUpdated(true);

      // setInvoice([file]);
      // setInvoice2([file]);

      if (file) {
        // setInvoiceUpdated(false);
        handleImageUpload({ images: [file], setImages: setInvoice1 })
          .then((res) => {
            // console.log(res);
            // const img_url = res;
            setInvoice1(res);
            const token = `Bearer ${localStorage.getItem("access")}`;
            setLoading(true);
            const order_update = { invoice_url: res };
            //   console.log(order_update);
            apiInstace
              .patch(`/orders/update_order/${row.order.id}/`, { order_update, token })
              .then((res) => {
                //   console.log(res);
                toast.success("Invoice Uploaded!", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });

                setLoading(false);
              })
              .catch((err) => {
                // toast.error("Something Went Wrong", {
                //   position: "top-right",
                //   autoClose: 5000,
                //   hideProgressBar: true,
                //   closeOnClick: true,
                //   pauseOnHover: true,
                //   draggable: true,
                //   progress: undefined,
                //   theme: "light",
                // });
                console.log(err);
                setLoading(false);
              });

            // console.log(img_url);
            // setPost_data({...post_data,"icon":img_url})
            // setPost_data({...post_data,"share_url":img_url})
            // document.querySelector(".post_img").src=img_url;
          })
          .catch((err) => console.log(err));
      } else setInvoice1(row?.order?.invoice_url);
    };
    imginput.addEventListener("change", geturl);
  };

  // useEffect(() => {}, [invoice]);

  const deleteinvoice = () => {
    setInvoice1("");
    const token = `Bearer ${localStorage.getItem("access")}`;
    setLoading(true);
    const order_update = { invoice_url: "" };
    // console.log(order_update);
    apiInstace
      .patch(`/orders/update_order/${row.order.id}/`, { order_update, token })
      .then((res) => {
        // console.log(res);
        toast.success("Invoice Deleted!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(err);
        setLoading(false);
      });
  };

  const formattedAddress = () => {
    const temp = [
      row?.order?.address,
      row?.order?.gaon,
      row?.order?.taluka,
      row?.order?.city,
      row?.order?.pincode,
      row?.order?.state,
    ].filter((val) => ![null, undefined].includes(val));

    const data = temp.join(", ");
    return data != "" ? data : row?.order?.address;
  };

  return (
    <>
      <TableRow
        hover
        // onClick={(event) => handleClick(event, row.name)}
        // role="checkbox"
        // aria-checked={isItemSelected}

        tabIndex={-1}
        key={row.id}
        // selected={isItemSelected}
        sx={{ cursor: "pointer" }}
        className={"row" + index}>
        {/* <TableCell padding="checkbox">
                        <CheckBox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell> */}
        <TableCell align='left'>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p>OrderID : {row.order.id}</p>
            <p>
              {new Date(row.created_at).toLocaleDateString(undefined, {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </p>
            <p>{new Date(row.created_at).toLocaleTimeString()}</p>
          </div>
        </TableCell>
        <TableCell
          component='th'
          id={labelId}
          scope='row'
          padding='normal'
          sx={{ minWidth: "10rem" }}
          onClick={() =>
            copyTextToClipboard(`${row?.order?.receiver_name} - ${row?.order?.receiver_contact}`)
          }>
          {
            // <GetUserDetail id={row.order.user} />
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}>
                <div></div>
                <Button sx={{ position: "relative", top: 0, right: 0 }}>
                  <ContentPasteRounded fontSize='small' />
                </Button>
              </div>
              <p>{row?.order?.receiver_name}</p>
              <p>{row?.order?.receiver_contact}</p>
              {/* <p>{row?.order?.user?.first_name?<>{row.order.user.first_name}</>:<>---</>} {row?.order?.user?.last_name?<>{row.order.user.last_name}</>:<>---</>}</p>
                        <p>{row?.order?.user?.phone}</p> */}
              {/* <GetUserDetail id={row?.order?.user_id} row={row} index={index} /> */}
              {/* {row?.order?.user?.address? (<p>{`${row?.order?.user?.address[0]?.city}, ${row?.user?.address[0]?.state}`}</p>):(<></>)} */}
            </div>
          }
        </TableCell>
        <TableCell
          sx={{ minWidth: "10rem" }}
          onClick={() => copyTextToClipboard(formattedAddress())}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <div></div>
            <Button sx={{ position: "relative", top: 0, right: 0 }}>
              <ContentPasteRounded fontSize='small' />
            </Button>
          </div>

          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <p>{formattedAddress()}</p>
          </div>
        </TableCell>

        <TableCell align='left'>{row?.order?.quantity}</TableCell>
        {/* <TableCell align="left">
                        {row.order_type === "CASH_ON_DELIVERY" ? (
                          <p>Cash on Delivery</p>
                        ) : (
                          ""
                        )}
                      </TableCell> */}

        <TableCell align='left'>{row?.order?.total_cost}</TableCell>
        {/* <TableCell>{row?.price}</TableCell> */}
        <TableCell>
          {row?.order?.order_type == "HOME_DELIVERY" ? "PREPAID" : row?.order?.order_type}
        </TableCell>
        {/* <TableCell>{row?.order?.payment_status}</TableCell> */}
        <TableCell align='left'>
          {row?.order?.order_type != "CASH_ON_DELIVERY" ? (
            <select
              value={row?.order?.payment_status}
              className='table-action-select'
              onChange={(e) => handlePaymentStatus(e.target.value, row.order.id)}>
              <option value='PENDING'>PENDING</option>
              <option value='SUCCESS'>SUCCESS</option>
              <option value='FAILED'>FAILED</option>
            </select>
          ) : row?.order?.status == "DELIVERED" ? (
            <div className='table-action-select' style={{ color: "green" }}>
              SUCCESS
            </div>
          ) : (
            <div className='table-action-select'>{row?.order?.payment_status}</div>
          )}
        </TableCell>

        <TableCell align='left'>
          {/* {row.action ? ( */}
          {/* <select className="table-action-select">
                            <option>Delivered</option>
                            <option>In-Progress</option>
                            <option>Pending</option>
                          </select> */}
          {row?.order?.status == "DELIVERED" ? (
            <div className='table-action-select' style={{ color: "green" }}>
              {row?.order?.status}
            </div>
          ) : (
            <select
              value={row?.order?.status}
              className='table-action-select'
              onChange={(e) => handleStatus(e.target.value, row.order.id)}
              disabled={row?.order?.status == "DELIVERED"}
              style={{ color: "black" }}>
              {/* <option value="PENDING">PENDING</option> */}
              {row?.order?.status == "PENDING"
                ? [
                    { value: "PENDING", label: "PENDING" },
                    { value: "IN_PROGESS", label: "IN PROGRESS" },
                    //   { value: "DELIVERED", label: "DELIVERED" },
                    { value: "REJECTED", label: "REJECTED" },
                  ].map((status) => (
                    <option key={status.value} value={status.value}>
                      {status.label}
                    </option>
                  ))
                : null}
              {row?.order?.status == "IN_PROGESS"
                ? [
                    { value: "IN_PROGESS", label: "IN PROGRESS" },
                    { value: "DELIVERED", label: "DELIVERED" },
                    { value: "REJECTED", label: "REJECTED" },
                  ].map((status) => (
                    <option key={status.value} value={status.value}>
                      {status.label}
                    </option>
                  ))
                : null}
              {row?.order?.status == "DELIVERED"
                ? [{ value: "DELIVERED", label: "DELIVERED" }].map((status) => (
                    <option key={status.value} value={status.value}>
                      {status.label}
                    </option>
                  ))
                : null}
              {row?.order?.status == "REJECTED"
                ? [
                    { value: "REJECTED", label: "REJECTED" },
                    { value: "PENDING", label: "PENDING" },
                  ].map((status) => (
                    <option key={status.value} value={status.value}>
                      {status.label}
                    </option>
                  ))
                : null}
              {/* {value == 3
                  ? [
                      { value: "REJECTED", label: "REJECTED" },
                      { value: "PENDING", label: "PENDING" },
                    ].map((status) => (
                      <option key={status.value} value={status.value}>
                        {status.label}
                      </option>
                    ))
                  : null} */}
            </select>
          )}
          {/* ) : (
                          <p>Uncompleted</p>
                        )} */}
        </TableCell>

        <TableCell>
          {row?.order?.status === "IN_PROGESS" ? (
            <>
              {invoice1 == "" || row?.order?.invoice_url == null ? (
                <>
                  <button className='create-new-post-button' onClick={handleupload1}>
                    <input
                      type='file'
                      name='pdfFile'
                      accept='.pdf'
                      hidden
                      id={`order_invoice${index}`}
                    />
                    Upload
                  </button>
                </>
              ) : (
                <div style={{ display: "flex" }}>
                  <a
                    target='_blank'
                    href={row?.order?.invoice_url != null ? row?.order?.invoice_url : invoice1}
                    download
                    className='create-new-post-button'>
                    Download
                  </a>
                  <DeleteOutlineIcon onClick={deleteinvoice} />
                </div>
              )}
            </>
          ) : row?.order?.status === "DELIVERED" ? (
            row?.order?.invoice_url == null ? (
              <p className='status-progress'>Waiting</p>
            ) : (
              <a
                target='_blank'
                href={row?.order?.invoice_url}
                download
                className='create-new-post-button'
                style={{ textAlign: "center" }}>
                Download
              </a>
            )
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen1(!open1)}>
            {open1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open1} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div'>
                Items
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>Product Details</TableCell>
                    <TableCell align='left'>Quantity</TableCell>
                    {row?.order?.status != "PENDING" && (
                      <TableCell align='left'>Tracking Id</TableCell>
                    )}
                    <TableCell align='left'>Share Price</TableCell>
                    <TableCell align='left'>Retail Price</TableCell>
                    {/* <TableCell align="left">Product Status</TableCell> */}
                  </TableRow>
                </TableHead>
                {/* {cartitems.map((row) => { */}
                {/* return <Row1 row={row} setLoading={setLoading} />; */}
                <Row1
                  row={row}
                  setLoading={setLoading}
                  value={value}
                  dispTrackID={row?.order?.status != "PENDING"}
                />
                {/* })} */}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const RowCopy = ({ row, orderData, labelId, index, setLoading }) => {
  const [status, setStatus] = useState(row.status);

  const handleStatus = (value, id) => {};

  const handlePaymentStatus = (value, id) => {};
  const [open1, setOpen1] = useState(false);

  const [invoice, setInvoice] = useState("");
  const [invoice2, setInvoice2] = useState("");
  const [invoice1, setInvoice1] = useState("");

  const [showrow] = useState(true);

  const [invoiceUpdated, setInvoiceUpdated] = useState(false);
  const handleupload1 = () => {};

  //   useEffect(() => {
  //     if (invoice != "" && invoiceUpdated) {
  //       handleImageUpload({ images: invoice2, setImages: setInvoice2 })
  //         .then((res) => {
  //           // console.log(res);
  //           const img_url = res;
  //           setInvoice1(img_url);
  //           const token = `Bearer ${localStorage.getItem("access")}`;
  //           setLoading(true);
  //           const order_update = { invoice_url: img_url };
  //           console.log(order_update);
  //           apiInstace
  //             .patch(`/orders/update_order/${row.order.id}/`, { order_update, token })
  //             .then((res) => {
  //               //   console.log(res);
  //               toast.success("Invoice Uploaded!", {
  //                 position: "top-right",
  //                 autoClose: 5000,
  //                 hideProgressBar: true,
  //                 closeOnClick: true,
  //                 pauseOnHover: true,
  //                 draggable: true,
  //                 progress: undefined,
  //                 theme: "light",
  //               });
  //               setLoading(false);
  //             })
  //             .catch((err) => {
  //               // toast.error("Something Went Wrong", {
  //               //   position: "top-right",
  //               //   autoClose: 5000,
  //               //   hideProgressBar: true,
  //               //   closeOnClick: true,
  //               //   pauseOnHover: true,
  //               //   draggable: true,
  //               //   progress: undefined,
  //               //   theme: "light",
  //               // });
  //               console.log(err);
  //               setLoading(false);
  //             });

  //           // console.log(img_url);
  //           // setPost_data({...post_data,"icon":img_url})
  //           // setPost_data({...post_data,"share_url":img_url})
  //           // document.querySelector(".post_img").src=img_url;
  //         })
  //         .catch((err) => console.log(err));
  //     } else setInvoice1(row?.order?.invoice_url);
  //   }, [invoice]);

  const deleteinvoice = () => {};

  const formattedAddress = () => {
    const temp = [
      row?.order?.address,
      row?.order?.gaon,
      row?.order?.taluka,
      row?.order?.city,
      row?.order?.pincode,
      row?.order?.state,
    ].filter((val) => ![null, undefined].includes(val));

    const data = temp.join(", ");
    return data != "" ? data : row?.order?.address;
  };

  return (
    <>
      {showrow ? (
        <>
          <TableRow
            hover
            tabIndex={-1}
            key={row.id}
            sx={{ cursor: "pointer" }}
            className={"row" + index}>
            <TableCell align='left'>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p>OrderID : {row.order.id}</p>
                <p>
                  {new Date(row.created_at).toLocaleDateString(undefined, {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </p>
                <p>{new Date(row.created_at).toLocaleTimeString()}</p>
              </div>
            </TableCell>
            <TableCell
              component='th'
              id={labelId}
              scope='row'
              padding='normal'
              sx={{ minWidth: "10rem" }}>
              {
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p>{row?.order?.receiver_name}</p>
                  <p>{row?.order?.receiver_contact}</p>{" "}
                </div>
              }
            </TableCell>
            <TableCell sx={{ minWidth: "10rem" }}>
              {/* <div
                style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div></div>
                <Button sx={{ position: "relative", top: 0, right: 0 }}>
                  <ContentPasteRounded fontSize='small' />
                </Button>
              </div> */}

              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <p>{formattedAddress()}</p>
              </div>
            </TableCell>

            <TableCell align='left'>{row?.order?.quantity}</TableCell>
            <TableCell align='left'>{row?.order?.total_cost}</TableCell>
            <TableCell>
              {row?.order?.order_type == "HOME_DELIVERY" ? "PREPAID" : row?.order?.order_type}
            </TableCell>
            <TableCell align='left'>
              {row?.order?.order_type == "CASH_ON_DELIVERY" && row?.order?.status == "DELIVERED" ? (
                <div className='table-action-select' style={{ color: "green" }}>
                  SUCCESS
                </div>
              ) : (
                <div
                  value={row?.order?.payment_status}
                  className='table-action-select'
                  onChange={(e) => handlePaymentStatus(e.target.value, row.order.id)}>
                  {row?.order?.payment_status}
                </div>
              )}
            </TableCell>
            <TableCell align='left'>
              {row?.order?.status == "DELIVERED" ? (
                <div className='table-action-select' style={{ color: "green" }}>
                  {row?.order?.status}
                </div>
              ) : (
                <div
                  value={row?.order?.status}
                  className='table-action-select'
                  onChange={(e) => handleStatus(e.target.value, row.order.id)}
                  disabled={row?.order?.status == "DELIVERED"}
                  style={{ color: "black" }}>
                  {row?.order?.status}
                </div>
              )}
            </TableCell>

            <TableCell>
              {row?.order?.status === "IN_PROGESS" ? (
                <>
                  {invoice1 == "" || row?.order?.invoice_url == null ? (
                    <button className='create-new-post-button' onClick={handleupload1}>
                      <input
                        type='file'
                        name='pdfFile'
                        accept='.pdf'
                        hidden
                        // id={`order_invoice${index}`}
                      />
                      Upload
                    </button>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <a
                        target='_blank'
                        href={row?.order?.invoice_url != null ? row?.order?.invoice_url : invoice1}
                        download
                        className='create-new-post-button'>
                        Download
                      </a>
                      <DeleteOutlineIcon onClick={deleteinvoice} />
                    </div>
                  )}
                </>
              ) : row?.order?.status === "DELIVERED" ? (
                row?.order?.invoice_url == null ? (
                  <p className='status-progress'>Waiting</p>
                ) : (
                  <a
                    target='_blank'
                    href={row?.order?.invoice_url}
                    download
                    className='create-new-post-button'
                    style={{ textAlign: "center" }}>
                    Download
                  </a>
                )
              ) : (
                <></>
              )}
            </TableCell>
            <TableCell>
              <IconButton aria-label='expand row' size='small' onClick={() => setOpen1(!open1)}>
                {open1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open1} timeout='auto' unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Typography variant='h6' gutterBottom component='div'>
                    Items
                  </Typography>
                  <Table size='small' aria-label='purchases'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Product Details</TableCell>
                        <TableCell align='left'>Quantity</TableCell>
                        <TableCell align='left'>Tracking Id</TableCell>
                        <TableCell align='left'>Share Price</TableCell>
                        <TableCell align='left'>Retail Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <Row1 row={row} setLoading={setLoading} />
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
const OrderTable = ({
  getOrder,
  value,
  fetchAgain,
  setFetchAgain,
  fetchOrders,
  tableUseRefs,
  status,
  pageData,
}) => {
  const [order_type, setOrder_type] = useState(["CASH_ON_DELIVERY"]);
  const [payment_status, setPaymentStatus] = useState("");

  // console.log(getOrder);

  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);
  const [orderData, setOrderData] = useState([...getOrder]);
  const [oriorderData, setOriOrderData] = useState([...getOrder]);

  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [products_data, setProducts_data] = useState([]);

  const [cartitemsall, setallCartitems] = useState([]);
  const [receiver_name, setReceiverName] = useState("");
  // const [allcart, setallCart] = useState([]);

  // const requestOptions = {
  //   headers: {
  //     method: "GET",
  //     Authorization: `Bearer ${localStorage.getItem("access")}`,
  //   },
  // };
  const [product, setProduct] = useState(null);

  const [name] = useDebounce(receiver_name, 600);

  useEffect(() => {
    // console.log(order_type);
    fetchOrders({
      order_type: order_type[0] || "CASH_ON_DELIVERY",
      payment_status,
      order_status: status,
      receiver_name: name || undefined,
      product: product === "all" ? undefined : product,
    });
  }, [order_type, payment_status, status, name, product, fetchAgain]);

  useEffect(() => {
    setOrderData([...getOrder]);
  }, [getOrder]);

  // const [users, setUsers] = useState([]);
  // const [addresses, setAddresses] = useState([]);

  //   useEffect(() => {
  //     console.log({ allitesm: cartitemsall });
  //   }, [cartitemsall]);

  const [forceFilter, setForceFilter] = useState(true);

  // useEffect(() => {
  //   // console.log("filtered get orders in table");
  //   // console.log(getOrder);
  //   // let filteredbyordertype = getOrder.filter((row) => {
  //   //   return row?.order?.order_type.toLowerCase() == order_type.toLowerCase();
  //   // })
  //   // setOrderData([...filteredbyordertype])

  //   // setOriOrderData([...getOrder]);
  //   setOriOrderData([...getOrder]);
  //   //console.log(payment_status, order_type)

  //   const filteredOrders = getOrder.filter((row) => {
  //     const isPrepaid =
  //       order_type.includes("HOME_DELIVERY") &&
  //       row?.order?.order_type?.toLowerCase() === "HOME_DELIVERY"?.toLowerCase();
  //     const isCashOnDelivery =
  //       order_type.includes("CASH_ON_DELIVERY") &&
  //       row?.order?.order_type?.toLowerCase() === "CASH_ON_DELIVERY"?.toLowerCase();

  //     const isProductMatch = product === "all" || row?.product?.id === product;

  //     const isPaymentStatusMatch = row?.order?.payment_status === payment_status;

  //     if (isPrepaid)
  //       console.log({
  //         //  row,
  //         // isPrepaid,
  //         // isCashOnDelivery,
  //         // isProductMatch,
  //         payment_status: row?.order?.payment_status,
  //         isPaymentStatusMatch,
  //       });

  //     return ((isPrepaid && isPaymentStatusMatch) || isCashOnDelivery) && isProductMatch;
  //   });

  //   setOrderData([...filteredOrders]);
  // }, [getOrder, product, order_type, payment_status, forceFilter]);

  // useEffect(() => {
  //   // cartitemsall
  // }, [product]);

  // useEffect(() => {
  //   console.log(getOrder)
  //   let filteredbyordertype = getOrder.filter((row) => {
  //     return row?.order?.order_type.toLowerCase() == order_type.toLowerCase();
  //   })
  //   setOrderData([...filteredbyordertype])

  // }, [order_type]);

  // useEffect(() => {
  // //   // call all cart items for each prod and store in cartitemsall state array

  //   const fetchdata = async () => {
  //     let arr = await Promise.all(
  //       orderData.map(async (order) => {
  //         const requestOptions = {
  //           params: {
  //             order: order.id,
  //           },
  //         };
  //         const cartdata = await apiInstace.get(`/orders/getorders`, requestOptions)

  //         // user = { ...user, network: userResponse.data };
  //         console.log({ "cart": cartdata.data.results })
  //         return cartdata.data.results
  //       }))

  //     console.log(arr)
  //     console.log(product)
  //     let filteredcart = []
  //     const filtercartbyproduct = arr.map((cartitem) => {
  //       if (cartitem[0].product.id === product) {
  //         console.log(cartitem[0])
  //         filteredcart.push(cartitem[0].id)

  //       }
  //     })
  //     console.log(filteredcart)

  //     setallCart(arr)
  //   };

  //   fetchdata()
  // }, [orderData])

  useEffect(() => {
    apiInstace
      .get(`/products/getproducts/`)
      .then((res) => {
        var products = [];
        // console.log(res.data.results);
        res.data.results.map((item) => [products.push({ id: item.id, name: item.name })]);
        setProducts_data(products);
        setProduct("all");
      })
      .catch((err) => console.log(err));

    // apiInstace
    //   .get(`/users/getUsers/`)
    //   .then((res) => {
    //     // console.log(res.data.users);
    //     setUsers([...res.data.results]);
    //   })
    //   .catch((err) => console.log(err));
    // console.log(user.data);
    // console.log(users);

    // apiInstace
    //   .get(`/address/getAddress/`)
    //   .then((res) => {
    //     // console.log(res.data);
    //     // console.log(res.data.results);
    //     setAddresses([...res.data.results]);
    //   })
    //   .catch((err) => console.log(err));
    // console.log(user.data);
    // console.log(users);
  }, []);
  //  console.log(product);

  // const GetAddressdetail = ({ id }) => {
  //   let address = {};
  //   // console.log(id);
  //   address = addresses?.filter((item) => {
  //     return item.id == id;
  //   });
  //   return (
  //     <>
  //       {address.length != 0 ? (
  //         <>
  //           <p>{address[0]?.address}</p>
  //           <p>{`${address[0]?.city}, ${address[0]?.state}`}</p>
  //         </>
  //       ) : (
  //         <>---</>
  //       )}
  //     </>
  //   );
  // };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    padding: 4,
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#ffffff",
    overflowX: "scroll",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    border: "none",
    borderRadius: "12px",
    boxShadow: 24,
    backdropfilter: "blur(4px)",
  };

  const handleDelete = (orderid) => {
    if (orderid >= 0) {
      console.log(orderid);

      apiInstace
        .delete(`/orders/deleteOrder/${orderid}/`)
        .then((res) => {
          console.log(res);
          toast.success("Order Deleted! It may take few minutes to reflect the changes", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // setLoading(false)
          handleClose();
        })
        .catch((err) => {
          toast.error("Something Went Wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          console.log(err);
        });
    }
  };
  const [open, setOpen] = React.useState(false);

  const [id, setId] = useState(-1);
  const handleOpen = (id) => {
    setId(id);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelected = rows.map((n) => n.name);
  //     setSelected(newSelected);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    // console.log(oriorderData[[0]]);
    // console.log(order_type);
    fetchOrders({
      order_type: order_type[0] || "CASH_ON_DELIVERY",
      payment_status,
      order_status: status,
      page: newPage,
      receiver_name,
      product: product === "all" ? undefined : product,
    });
    //apply this original and set filter oriorderData contains data with status already solved
    //u filter for prod and order type
    // if (product === null) {
    //   let filteredbyordertype = oriorderData.filter((row) => {
    //     // console.log(row?.order_type?.toLowerCase());
    //     return order_type?.some(
    //       (type) => type.toLowerCase() == row?.order?.order_type?.toLowerCase()
    //     );
    //   });
    //   setOrderData(filteredbyordertype);
    // } else {
    //   let iter = 0;
    //   // console.log(i++);
    //   let filteredbyordertype = oriorderData.filter((row) => {
    //     // console.log(row?.order_type?.toLowerCase());
    //     // console.log(i++);
    //     return row?.order?.order_type?.toLowerCase() == order_type?.toLowerCase();
    //   });
    //   setOrderData(filteredbyordertype);
    // }

    // let filteredbyordertype = oriorderData.filter((row) => {
    //   // console.log(row?.order_type?.toLowerCase());
    //   return order_type?.some(
    //     (type) => type.toLowerCase() == row?.order?.order_type?.toLowerCase()
    //   );
    // });
    // setOrderData(filteredbyordertype);

    setPage(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = 0; // page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderData?.length) : 0;

  // const visibleRows = React.useMemo(
  //   () =>
  //     stableSort(rows, getComparator(order, orderBy)).slice(
  //       page * rowsPerPage,
  //       page * rowsPerPage + rowsPerPage
  //     ),
  //   [order, orderBy, page, rowsPerPage]
  // );
  // const [disabled, setDisabled] = useState(true)

  // useEffect(() => {
  // handleUserInfo();
  // }, [getOrder]);
  // const tableRef = useRef(null);

  // useEffect(()=>{
  //   let edit={};
  //   let trackingids={};
  //   orderData.map((item,index)=> {
  //     edit={...edit,[index]:true};
  //     trackingids={...trackingids,[index]:item.tracking_id};
  //   }
  //   )
  //   setEdit({...edit});
  //   setTrackingID({...trackingids})
  //   // console.log(trackingids);
  //   // console.log(edit);
  // },[orderData])

  const [refToUse, setRefToUse] = useState(tableUseRefs[0]);

  const tabsData = {
    0: "PENDING",
    1: "IN PROGRESS",
    2: "DELIVERED",
    3: "REJECTED",
    4: "ALL",
  };
  const [tableName, setTableName] = useState();

  useEffect(() => {
    setRefToUse(tableUseRefs[value]);
    setTableName(tabsData[value]);
  }, [value]);

  return (
    <div className='order-table-main'>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'>
            <Box sx={style}>
              <Typography id='modal-modal-title' variant='h6' component='h2'>
                Are you Sure, You want to Delete?
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <button
                  className='create-new-post-button'
                  style={{ margin: "10px 20px" }}
                  onClick={() => handleDelete(id)}>
                  Delete
                </button>
                <button
                  className='create-new-post-button'
                  style={{ margin: "10px 20px" }}
                  onClick={handleClose}>
                  Cancel
                </button>
              </div>
            </Box>
          </Modal>
          <EnhancedTableToolbar
            numSelected={selected.length}
            product={product}
            setProduct={setProduct}
            payment_status={payment_status}
            setPaymentStatus={setPaymentStatus}
            products_data={products_data}
            tableRef={refToUse}
            orderData={orderData}
            setOrderData={setOrderData}
            oriorderData={oriorderData}
            order_type={order_type}
            setOrder_type={setOrder_type}
            // users={users}
            cartitemsall={cartitemsall}
            setallCartitems={setallCartitems}
            setForceFilter={setForceFilter}
            tableName={tableName}
            receiver_name={receiver_name}
            setReceiverName={setReceiverName}
            // query={query}
            // setQuery={setQuery}
          />

          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              // aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              exportButton={true}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                // rowCount={orderData?.length}
              />
              <TableBody>
                {orderData
                  // rowsPerPage > 0
                  // ? orderData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // : orderData
                  // ?.filter((item, index, self) => {
                  //   // Check if the current item's 'name' field is unique in the array
                  //   return (
                  //     index === self.findIndex((otherItem) => otherItem.order.id === item.order.id)
                  //   );
                  // })
                  .map((row, index) => {
                    // handleEdit(index,false);
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // const [disabled, setDisabled] = useState(true);
                    // let disabled=true;

                    // const handledit=()=>{
                    //   // const disabled=document.querySelector(`.row${index}`).querySelector("input").disabled;
                    //   // console.log(disabled);
                    //      handleEdit(index,true);
                    //   // console.log(disabled);

                    // }
                    // const handlsave=()=>{
                    //   document.querySelector(`.row${index}`).querySelector("input").disabled=true;
                    //   document.querySelector(`.row${index}`).querySelector("button").innerText="Edit";
                    // }

                    return (
                      <Row
                        key={row.id}
                        row={row}
                        id={row.id}
                        orderData={orderData}
                        oriorderData={oriorderData}
                        // users={users}
                        labelId={labelId}
                        index={index}
                        setLoading={setLoading}
                        handleOpen={handleOpen}
                        fetchAgain={fetchAgain}
                        setFetchAgain={setFetchAgain}
                        // fetchOrders={fetchOrders}
                        cartitemsall={cartitemsall}
                        setallCartitems={setallCartitems}
                        product={product}
                        value={value}
                      />
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>{" "}
            <Table
              sx={{ minWidth: 750, display: "none" }}
              size={dense ? "small" : "medium"}
              exportButton={true}
              ref={tableUseRefs[value]}>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                // rowCount={orderData?.length}
              />
              <TableBody>
                {
                  // rowsPerPage > 0
                  // ? orderData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // :
                  orderData
                    // )
                    //   ?.filter((item, index, self) => {
                    //     return (
                    //       index === self.findIndex((otherItem) => otherItem.order.id === item.order.id)
                    //     );
                    //   })
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <RowCopy
                          key={row.id}
                          row={row}
                          id={row.id}
                          orderData={orderData}
                          oriorderData={oriorderData}
                          // users={users}
                          labelId={labelId}
                          index={index}
                          setLoading={setLoading}
                          handleOpen={handleOpen}
                          fetchAgain={fetchAgain}
                          setFetchAgain={setFetchAgain}
                          fetchOrders={fetchOrders}
                          cartitemsall={cartitemsall}
                          setallCartitems={setallCartitems}
                          product={product}
                          value={value}
                        />
                      );
                    })
                }
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            component='div'
            count={pageData.count}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
        {/* <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        /> */}
      </Box>
    </div>
  );
};

export default OrderTable;
