import React, { useEffect, useState, useContext } from "react";
import Sales from "./Layout/Sales";
import Customers from "./Layout/Customers";
import AllOrders from "./Layout/AllOrders";
import Marketing from "./Layout/Marketing";
import Allproducts from "./Layout/Allproducts";
import Abandoned from "./Layout/Abandoned";
import RecentOrders from "./Layout/RecentOrders";
import Summary from "./Layout/Summary";
import { Box } from "@mui/material";
import DashboardContext from "../Context/DashboardContext";
// import DashboardState from "../Context/DashboardState";

const Dashboard = () => {
  const context = useContext(DashboardContext);
  const {
    customers: customersData,
    getCustomers,
    orders,
    getOrders,
    getProducts,
    products,
    crops,
    getCrops,
    posts,
    getPosts,
  } = context;

  const [activeUsersCount, setActiveUsersCount] = useState(0);

  const fetchActiveUsers = async () => {
    const data = await getCustomers({ is_active: true });
    if (data) setActiveUsersCount(data.count);
  };

  useEffect(() => {
    fetchActiveUsers();
    getCustomers();
    getOrders({ size: 100 });
    getProducts();
    getCrops();
    getPosts();
  }, []);

  const [ordersCount, setOrderCount] = useState({
    pending: 0,
    IN_PROGESS: 0,
    complete: 0,
    reject: 0,
    all: orders?.count,
  });

  useEffect(() => {
    const fetchOrdersCounts = async () => {
      const pending = await getOrders({ order_status: "PENDING" });
      const inProgress = await getOrders({ order_status: "IN_PROGESS" });
      const delivered = await getOrders({ order_status: "DELIVERED" });
      const rejected = await getOrders({ order_status: "REJECTED" });

      setOrderCount({
        pending: pending?.count,
        IN_PROGESS: inProgress.count,
        complete: delivered.count,
        reject: rejected.count,
        all: pending.count + inProgress.count + delivered.count + rejected.count,
      });
    };
    fetchOrdersCounts();
  }, []);

  return (
    <Box>
      <Box className='dashboard-main'>
        <Box className='sales'>
          <Sales orders={orders?.results || []} />
        </Box>
        <Box className='customers'>
          <Customers customersData={customersData} activeUsersCount={activeUsersCount} />
        </Box>
        <Box className='allorders'>
          <AllOrders orders={orders} ordersCount={ordersCount} />
        </Box>
        {/* <Box className="marketing">
          <Marketing />
        </Box> */}
        <Box className='allproducts'>
          <Allproducts products={products} />
        </Box>
        <Box className='abandoned'>
          <Abandoned crops={crops} posts={posts} />
        </Box>
        <Box className='recentorders'>
          <RecentOrders orders={orders?.results || []} ordersCount={ordersCount} />
        </Box>
        <Box className='summary'>
          <Summary orders={orders?.results || []} />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
